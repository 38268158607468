import React, { Component } from 'react';
import { Table, Form, FormLabel, FormGroup, FormControl, DropdownButton, Dropdown, FormCheck } from 'react-bootstrap';
import './App.css';
import DropdownItem from 'react-bootstrap/DropdownItem';
// import DropdownToggle from 'react-bootstrap/DropdownToggle';

class App extends Component {


  constructor(props) {
    super(props);
    this.state = { valor: '' };
    this.readFile = this.readFile.bind(this);
  }

  componentDidMount() {
    fetch('data/btc_prices_4.json')
      .then(res => res.json())
      .then(data => {
        console.log(data)
        var json = data.values;
        var array = [];
        json.map((value) => {
          var obj = Object.entries(value);
          array.push(obj);
          return null;
        })
        this.setState({
          valor: array
        });
      });
  }

  csvJSON(csv) {

    var lines = csv.split("\n");

    var result = [];

    var headers = lines[0].split(",");

    for (var i = 1; i < lines.length; i++) {

      var obj = {};
      var currentline = lines[i].split(",");

      for (var j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j];
      }

      result.push(obj);

    }

    //return result; //JavaScript object
    return JSON.stringify(result); //JSON
  }

  readFile(event) {
    var fileReader = new FileReader();
    var file = event.target.files[0];
    fileReader.onloadend = () => {
      var result = fileReader.result;
      var json = JSON.parse(result).values;
      var array = [];
      json.map((value) => {
        var obj = Object.entries(value);
        array.push(obj);
        return null;
      })
      this.setState({
        valor: array
      });
    };
    fileReader.readAsText(file);

  }

  calculateRSI(ind, array) {

    let positives = 0, negatives = 0;
    debugger;
    for (var i = 0; i < ind; i++) {
      var dif = array[i + 1][1][1] - array[i][1][1];
      if (dif > 0) {
        positives += dif;
      } else {

        negatives += (dif * -1);
      }
    }
    var rs = (1 + (positives / negatives));
    return isNaN((100 - (100 / rs))) ? 0 : (100 - (100 / rs));
    // return  (100 -(100/rs));
  }

  render() {

    var rows = this.state.valor ? this.state.valor.map(function (value, ind, array) {
      let date = new Date(value[0][1]).toLocaleString();
      return (
        <tr key={ind}>
          <td>{value[1][1]}</td>
          <td>{this.calculateRSI(ind, array).toFixed(2)}</td>
          <td>-</td>
          <td>{date}</td>
        </tr>
      )
    }.bind(this)) : null;
    return (
      <div className="App">
        <header style={{ background: "orangered" }}>
          <h1>Marinel-lo STATS</h1>
          <h2>Calculate your profit now !</h2>
        </header>
        <section style={{ display: "flex" }}>

          <Form style={{ width: "50%" }}>
            <h3>Indicators</h3>
            <FormGroup>
              <FormLabel>EMA</FormLabel>
              <FormControl placeholder="Low EMA"></FormControl>
              <FormControl placeholder="High EMA"></FormControl>
              <Dropdown>Others</Dropdown>
              <DropdownButton>
                <DropdownItem>RSI</DropdownItem>
              </DropdownButton>
              <FormControl placeholder="Value"></FormControl>
            </FormGroup>
          </Form>
          <Form style={{ width: "50%" }}>
            <h3>Options</h3>
            <FormGroup>
              <FormLabel>Stop Loss</FormLabel>
              <FormCheck checked></FormCheck>

              <Dropdown>Others</Dropdown>
              <DropdownButton>
                <DropdownItem>Only %</DropdownItem>
              </DropdownButton>
              <FormControl placeholder="Value"></FormControl>
            </FormGroup>
          </Form>
        </section>
        <section>
          <input type="file" onChange={this.readFile}></input>
          {/* {this.state.valor} */}
          <Table striped bordered hover variant="dark">
            <thead>
              <tr>
                <th>Precio</th>
                <th>RSI</th>
                <th>EMa</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {rows}
            </tbody>
          </Table>
        </section>
      </div>
    );
  }

}

export default App;
